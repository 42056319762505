import { PlusOutlined } from '@ant-design/icons';
function UploadButton() {
    return (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Ảnh đại diện</div>
        </div>
    );
}

export default UploadButton;
