import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMinus, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import ReactDOM from 'react-dom/client';
import App from '~/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store/store';
import GlobalStyles from './assets/global-style';
import { BrowserRouter, Router } from 'react-router-dom';

library.add(faMinus, faPlus, faXmark);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GlobalStyles>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </GlobalStyles>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
