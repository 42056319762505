import './footer.css';
import '../../../../assets/css/grid.css';
function Footer() {
    return (
        <>
            <footer class="footer">
                <div class="grid wide footer__content">
                    <div class="row">
                        <div class="col l-2-4 m-4 c-6">
                            <h3 class="footer__heading">Chăm sóc khách hàng</h3>
                            <ul class="footer-list">
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Trung Tâm Trợ Giúp
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Homecare Blog
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Homecare Mall
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Hướng Dẫn Mua Hàng
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Hướng Dẫn Bán Hàng
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Thanh Toán
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Homecare Xu
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Vận Chuyển
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Trả Hàng & Hoàn Tiền
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Chăm Sóc Khách Hàng
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Chính Sách Bảo Hành
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="col l-2-4 m-4 c-6">
                            <h3 class="footer__heading">Về Homecare</h3>
                            <ul class="footer-list">
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Giới Thiệu Về Homecare Việt Nam
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Tuyển Dụng
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Điều Khoản Homecare
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Chính Sách Bảo Mật
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Chính Hãng
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Kênh Người Bán
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Flash Sales
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Chương Trình Tiếp Thị Liên Kết Homecare
                                    </a>
                                </li>
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        Liên Hệ Với Truyền Thông
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="col l-2-4 m-4 c-12">
                            <h3 class="footer__heading">Thanh Toán</h3>
                            <ul class="footer-list footer-list__box">
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/visa.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/thanh_toan_1.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/thanh_toan_2.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/thanh_toan_3.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/thanh_toan_4.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/thanh_toan_5.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/thanh_toan_6.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/thanh_toan_7.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>
                            </ul>
                            <h3 class="footer__heading">ĐƠN VỊ VẬN CHUYỂN</h3>
                            <ul class="footer-list footer-list__box">
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_1.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_2.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_3.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_4.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_5.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_6.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_7.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_8.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_9.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <img src="/ship_10.png" alt="" class="footer-item__link-img" />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="col l-2-4 m-4 c-6">
                            <h3 class="footer__heading">Theo dõi chúng tôi trên</h3>
                            <ul class="footer-list">
                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <i class="footer-item__icon fa-brands fa-facebook"></i>
                                        Facebook
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <i class="footer-item__icon fa-brands fa-instagram"></i>
                                        Instagram
                                    </a>
                                </li>

                                <li class="footer-item">
                                    <a href="" class="footer-item__link">
                                        <i class="footer-item__icon fa-brands fa-linkedin"></i>
                                        LinkedIn
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="col l-2-4 m-8 c-6">
                            <h3 class="footer__heading">Đăng kí tài khoản Homecare ngay thôi</h3>
                            <div class="footer__download">
                                <a href="" class="footer__download-apps-link">
                                    <img src="/QR.png" alt="" class="footer__download-qr" />
                                </a>

                                <div class="footer__download-apps">
                                    <a href="" class="footer__download-apps-link">
                                        <img src="/google-play.png" alt="" class="footer__download-apps-img" />
                                    </a>

                                    <a href="" class="footer__download-apps-link">
                                        <img src="/app-store.png" alt="" class="footer__download-apps-img" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer__bottom">
                    <div class="grid wide">
                        <p class="footer__text">© 2023 Shopee. Tất cả các quyền được bảo lưu.</p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
